/** @class
 * @name Noise
 * @desc Monochromatic noise 2d
 * @return {object}
 */
import Canvas from "./canvas.js";
export default class Noise extends Canvas {
    constructor() {
        super()
        /*
         * You get a better performance with small tile size,
         * but the bad thing is that the repetition of the tile is more visible.
         * =
         * You get a more uniform noise with a big tile size,
         * but the performance is affected by the number of operations.
         */
        this.tile_size = 200
        // Helpers
        this.tile = new Canvas()
        // 
        this.alpha = BROWSER.mobile ? 0.05 : 0.1
        this.container = document.body
        this.container.appendChild(this.canvas)
        this.canvas.setAttribute("id", "noise")
        window.RAF.on("animate", (delta) => this.render(delta))
        window.SIZE.on("resize", (delta) => this.resize(delta))
        this.resize()
    }

    resize() {
        this.tile.setSize(this.tile_size, this.tile_size)
        this.setSize(WIDTH, HEIGHT)
    }

    render() {
        // Limpiamos stages
        this.clear()
        this.tile.clear()
        // Actualizamos noise
        this.update()
        this.draw(this.context)
    }

    draw(ctx) {
        ctx.globalAlpha = this.alpha
        // Rellenamos el rectángulo con el patrón
        ctx.fillStyle = ctx.createPattern(this.tile.canvas, 'repeat')
        ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)
    }

    // Genera un cuadrado con ruido en 2d
    update() {
        let pixels = new ImageData(this.tile_size, this.tile_size)
        // En cada iteración cambia el número de pixeles a recorrer por múltiplos de 4.
        // Esto genera más huecos y al mismo tiempo mejora performance.
        // ~~(Math.random() * 4) * 4
        for (let i = 0; i < pixels.data.length; i += 4) {
            // Mientras más pegado a 255 más blanco, más pegado a 0 más negro
            // let gray = ~~(Math.random() * 200)
            // set rgba
            pixels.data[i] = pixels.data[i + 1] = ~~(Math.random() * 255)
            pixels.data[i + 2] = ~~(Math.random() * 255)
            pixels.data[i + 3] = ~~(Math.random() * 255)
        }
        this.tile.context.putImageData(pixels, 0, 0)
    }

}