/** @class
 * @name Canvas
 * @return {object}
 */
const PX_RATIO = Math.min(1, 2)

export default class Canvas {
    constructor(dom_element) {
        this.container = document.body
        this.canvas = document.createElement("canvas")
        this.context = this.canvas.getContext("2d")
        // this.container.appendChild(this.canvas)
    }

    pixelize() {
        this.context.webkitImageSmoothingEnabled = false
        this.context.mozImageSmoothingEnabled = false
        this.context.msImageSmoothingEnabled = false
        this.context.imageSmoothingEnabled = false
    }

    setSize(_w, _h) {
        this.height = _h
        this.width = _w
        this.canvas.style.width = `${this.width}px`
        this.canvas.style.height = `${this.height}px`
        this.canvas.width = this.width * PX_RATIO
        this.canvas.height = this.height * PX_RATIO
        this.render()
    }

    get center() {
        return {
            x: this.width / 2,
            y: this.height / 2
        }
    }

    render() {
        this.clear()
    }

    background(_color = "black") {
        this.context.fillStyle = _color
        this.context.fillRect(0, 0, this.canvas.width, this.canvas.height)
    }
    
    fill(_color = "black") {
        this.context.fillStyle = _color
        this.context.fill()
    }

    stroke(_color = "black") {
        this.context.strokeStyle = _color
        this.context.stroke()
    }

    bg(_color) {
        this.background(_color)
    }

    drawImage(_img, params) {
        this.context.drawImage(_img, params.x, params.y, params.width, params.height)
    }

    clear() {
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
    }

    destroy() {
        this.context = null
        this.canvas = null
    }

}